<template>
  <v-card outlined flat>
    <v-card-title>
      {{ $t('common.action.create') }} {{ $t('common.messages.a_new_masc_fem') }} {{ $t('common.words.madmin') }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="validForm">
        <v-text-field v-bind:label="$t('common.labels.email')"
                      name="email"
                      type="email"
                      @keypress.enter.prevent="submit"
                      v-model="model.email"
                      :rules="[
                        (v) => v !== '' || $t('errors.email'),
                        (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        $t('errors.email')
                        ]"/>
        <v-text-field v-bind:label="$t('common.labels.username')"
                      name="username"
                      type="text"
                      @keypress.enter.prevent="submit"
                      v-model="model.username"
                      :rules="[(v) => v !== '' || $t('errors.empty', {the: $t('common.words.The_male'), name: $t('common.labels.musername')})]"/>

        <v-select
          v-model="model.roleId"
          :items="allowedRole"
          item-text="name"
          item-value="roleId"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn depressed color="primary" @click="submit"
             :loading="createMixin.state === createMixin.STATES.CREATING"
             :disabled="!validForm">
        {{ $t('common.action.create') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import IdmService from '@/services/idm.service';
import CreateMixin from '@/mixins/create.mixin';
import { allowedRole } from '@/services/auth.service';

export default {
  name: 'form-admin-form',
  mixins: [CreateMixin],
  data() {
    return {
      validForm: false,
      allowedRole,
      model: {
        email: '',
        username: '',
        roleId: 1,
      },
    };
  },
  methods: {
    createAdmin() {
      return IdmService.createAdmin(this.model);
    },
    submit() {
      this.$refs.form.validate();
      if (!this.validForm) return;
      this.create();
    },
  },
  created() {
    this.createMixin.config = {
      create: 'createAdmin',
    };
  },
};
</script>

<style scoped>

</style>
