<template>
  <v-col>
    <form-admin-form @created="creationCallback"/>
    <v-card flat>
      <v-card-title>
        <div>{{ $t(`pages.admins.title`) }}</div>
      </v-card-title>
      <v-card-text v-if="fetchMixin.state === fetchMixin.STATES.ERROR">
        <div>{{ $t(`errors.basic`) }}</div>
      </v-card-text>

      <v-card-text v-else-if="fetchMixin.state  === fetchMixin.STATES.EMPTY">
        {{ $t(`errors.unavailable`, $t('pages.admins.name')) }}
      </v-card-text>
      <v-card-text v-else-if="fetchMixin.state  === fetchMixin.STATES.IDLE || fetchMixin.state === fetchMixin.STATES.LOADING">
        <v-simple-table>
          <thead>
          <tr>
            <th>{{ $t(`common.labels.id`) }}</th>
            <th>{{ $t(`common.labels.identifier`) }}</th>
            <th>{{ $t(`common.labels.email`) }}</th>
            <th>{{ $t(`common.labels.status.name`) }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(admin) in admins" :key="admin.id">
            <td>{{ admin.id }}</td>
            <td>{{ admin.username }}</td>
            <td>{{ admin.email }}</td>
            <td class="green--text" v-if="admin.status === 'VALIDATED'">{{ $t(`common.labels.status.active`) }}</td>
            <td class="grey--text" v-else-if="admin.status === 'DISABLED'">{{ $t(`common.labels.status.deactivated`) }}</td>
            <td class="orange--text" v-else>{{ $t(`common.labels.status.inactive`) }}</td>
            <td class="text-right" >
              <v-btn icon @click.stop="disableAccount(admin.uniqueID)"  v-if="admin.status !== 'DISABLED' && admin.uniqueID !== currentUser.uniqueID">
                <v-icon>mdi-minus-circle-outline</v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import FormAdminForm from '@/components/forms/AdminForm.vue';
import IdmService from '@/services/idm.service';
import FetchMixin from '@/mixins/fech.mixin';
import AuthService from '@/services/auth.service';

export default {
  name: 'AdminsView',
  mixins: [FetchMixin],
  components: {
    FormAdminForm,
  },
  data: () => ({
    admins: [],
  }),
  computed: {
    currentUser() {
      return AuthService.getUser();
    },
  },
  methods: {
    creationCallback(newAdmin) {
      this.admins = [...this.admins, newAdmin];
    },
    fetchAdmins() {
      return IdmService.getAdmins();
    },
    async disableAccount(id) {
      await IdmService.disableAdmin(id);
      this.fetch();
    },
  },
  created() {
    this.fetchMixin.config = {
      fetch: 'fetchAdmins',
      dataField: 'admins',
    };
    this.fetch();
  },
};
</script>

<style scoped>
</style>
